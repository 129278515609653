import { FC } from 'react';
import cx from 'clsx';
import { Box } from '@mui/material';
import { Avatar, avatarType } from '@confidant-health/lib/ui/atoms/avatar';
import { Badge } from '@confidant-health/lib/ui/atoms/badge';
import { fontWeight, Text, textLevel } from '@confidant-health/lib/ui/atoms/typography';
import { formatTimeMessage, isUserOnline } from 'utils/CommonUtils';
import avatarIcon from 'assets/images/avatar.png';
import groupAvatarIcon from 'assets/images/group-avatar.png';
import { ProfileInfoRole } from 'components/v2/ProfileInfo';
import Typography from 'components/v2/Typography';
import { typography } from 'components/v2/Typography/index.constant';

import { SideUserDataT } from './Chats.types';
import { useStyles } from './Chats.styles';
import { CONNECTIONS_TYPES } from '../../../constants/CommonConstants';

type Props = {
  isActive: boolean;
  item: SideUserDataT;
  onlineUsers?: string[];
  selectContact: () => void;
};

const SideUserCard: FC<Props> = ({ isActive, selectContact, item, onlineUsers }) => {
  const classes = useStyles({ isCareTem: item.type === CONNECTIONS_TYPES.CARE_TEAM });

  const showTimeOnBottom = () => {
    if (!item?.lastMessage) return false;
    if (formatTimeMessage(item?.lastMessageTimestampUnix as any)?.length > 10) {
      if (item.type === CONNECTIONS_TYPES.CARE_TEAM && item?.lastMessage?.length >= 15) {
        return true;
      }
      if (item?.lastMessage?.length < 15) {
        return false;
      }
      return false;
    }
    return false;
  };
  const getDesignationText = () => {
    if (item?.type === CONNECTIONS_TYPES.CARE_NAVIGATOR) {
      return 'Care navigator';
    }
    if (item?.designation) {
      if (ProfileInfoRole[item?.designation?.toUpperCase()])
        return ProfileInfoRole[item?.designation?.toUpperCase()];
      return item?.designation;
    }
    return ProfileInfoRole[item?.type || 'PROVIDER'];
  };

  const getSelectedContactFullName = () => {
    if (item.type === CONNECTIONS_TYPES.PATIENT || item.type === CONNECTIONS_TYPES.CARE_TEAM) {
      if (item?.firstName && item?.lastName) return `${item?.firstName} ${item?.lastName}`;
    }
    return item?.fullName ?? item?.name;
  };

  return (
    <div
      onClick={selectContact}
      className={cx({
        [classes.userContactCard]: true,
        isActive,
      })}
    >
      <div
        className={
          item.type === CONNECTIONS_TYPES.CHAT_GROUP
            ? classes.profileWrapperCenterAligned
            : classes.profileWrapper
        }
      >
        {item?.thumbnail || item?.profilePicture ? (
          <Avatar
            variant={avatarType.CIRCLE}
            size={40}
            src={item?.thumbnail ?? item.profilePicture}
            name={item?.fullName}
            color={item?.colorCode}
          />
        ) : (
          <Box width={40} maxWidth={40} maxHeight={40}>
            <img
              src={item.type !== CONNECTIONS_TYPES.CHAT_GROUP ? avatarIcon : groupAvatarIcon}
              alt="avatar empty img"
              width="40px"
              height="40px"
            />
          </Box>
        )}
        {item.type !== 'CHAT_GROUP' && (
          <Box
            className={classes.onlinePresenceIndicator}
            style={{
              backgroundColor: isUserOnline(onlineUsers, item?.connectionId) ? '#51A365' : '#7d7d7d',
              color: isUserOnline(onlineUsers, item?.connectionId) ? '#51A365' : '#7d7d7d',
            }}
          >
            .
          </Box>
        )}
        <div className="userChatContentWrapper">
          <div className="userChatNameWrapper">
            {item?.name ? (
              <Box width="100%" display="flex" flexWrap="nowrap" gap={1} justifyContent="flex-start">
                <Text className="nickName" weight={fontWeight.SEMI_BOLD} level={textLevel.S}>
                  {getSelectedContactFullName()}
                </Text>
                <Box display="flex" flexDirection="row" justifyContent="space-between" maxWidth="90%" gap={1}>
                  {item?.type === CONNECTIONS_TYPES.PRACTITIONER ? (
                    <Badge
                      className={cx({
                        [classes.badge]: true,
                        [classes.provider]: item?.designation === ProfileInfoRole.PROVIDER,
                        [classes.supervising]: item?.designation === ProfileInfoRole.SUPERVISING,
                        [classes.care]: item?.designation === ProfileInfoRole.CARE,
                        [classes.coach]: item?.designation === ProfileInfoRole.COACH,
                        [classes.member]: item?.designation === ProfileInfoRole.MEMBER,
                        [classes.designation]: item?.designation === ProfileInfoRole.DESIGNATION,
                      })}
                    >
                      <Typography {...typography.body.normal.xsmall.semibold}>
                        {getDesignationText()}
                      </Typography>
                    </Badge>
                  ) : (
                    (item?.firstName || item?.lastName || item?.fullName || item?.name) && (
                      <Text className="nickName" weight={fontWeight.REGULAR} level={textLevel.S}>
                        {item.type === CONNECTIONS_TYPES.PATIENT || item.type === CONNECTIONS_TYPES.CARE_TEAM
                          ? item.uuid
                            ? `- ${item.uuid}`
                            : ''
                          : item?.firstName && item?.lastName && `  - ${item?.firstName} ${item?.lastName}`}
                      </Text>
                    )
                  )}
                  {item.type === CONNECTIONS_TYPES.CARE_TEAM && (
                    <div className={classes.badgeWrapperAlignStart}>
                      <Badge className="badge">Care team</Badge>
                    </div>
                  )}
                </Box>
              </Box>
            ) : (
              <>
                {item?.firstName || item?.lastName ? (
                  <Text className="nickName" weight={fontWeight.SEMI_BOLD} level={textLevel.S}>
                    {`${item?.firstName} ${item?.lastName}`}
                  </Text>
                ) : (
                  <Text className="nickName" weight={fontWeight.SEMI_BOLD} level={textLevel.S}>
                    {item?.fullName}
                  </Text>
                )}
              </>
            )}
          </div>
          <Box display="flex" flexDirection="row">
            <Text className="lastMessage" weight={fontWeight.MEDIUM} level={textLevel.S}>
              {item?.lastMessage || 'No message yet'}
            </Text>
            <Text className="lastMessageTime" weight={fontWeight.MEDIUM} level={textLevel.S}>
              {item?.lastMessage &&
                !showTimeOnBottom() &&
                `   •   ${formatTimeMessage(item?.lastMessageTimestampUnix as any)}`}
            </Text>
          </Box>
        </div>
      </div>
      <div className={classes.badgeWrapper}>
        {!!item?.unreadMessageCount && (
          <Badge className={classes.unreadMessageBadge}>{item.unreadMessageCount}</Badge>
        )}
      </div>
    </div>
  );
};

export default SideUserCard;

import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Box, ClickAwayListener } from '@mui/material';
import clsx from 'clsx';

import debounce from 'debounce';
import { fontWeight, Heading } from '@confidant-health/lib/ui/atoms/typography';
import { IconButton } from '@confidant-health/lib/ui/molecules/icon-button';
import { Avatar, avatarType } from '@confidant-health/lib/ui/atoms/avatar';
import { Badge, badgeType } from '@confidant-health/lib/ui/atoms/badge';
import { Icons } from '@confidant-health/lib/icons';
import { btnType } from '@confidant-health/lib/ui/atoms/button';
import { uuid4 } from 'lib/sendbird';
import { MessageInput } from 'pages/provider/chats/MessageInput';
import UserChatCard from 'pages/provider/chats/UserChatCard';
import { isUserOnline, sortingDesToAsc } from 'utils/CommonUtils';
import { getProfile } from 'redux/modules/profile/selectors';
import { getConversation } from 'redux/modules/conversation/selectors';
import { IAppointment } from '@confidant-health/lib/ui/templates/appointment-card';
import { getSocketState } from 'redux/modules/socket/selectors';
import { IChatListItem } from './Chats.types';
import { useStyles } from './Chats.styles';
import { ChatListItem } from './ChatListItem';

type Props = {
  isWaiting?: boolean;
  chatListItemList: IChatListItem[];
  appointment?: IAppointment;
  onClose: () => void;
  handleAddMessage: (message: Record<string, any>) => void;
  sessionStarted?: boolean;
};
const ChatUser: FC<Props> = ({
  isWaiting = false,
  chatListItemList,
  appointment,
  onClose,
  handleAddMessage,
  sessionStarted,
}) => {
  const classes = useStyles();
  const { messages } = useSelector(getConversation);
  const { onlineUsers } = useSelector(getSocketState);
  const { activeConnections, profile } = useSelector(getProfile);
  const [showDropDown, setShowDropDown] = useState(false);
  const [showLatestBtn, setShowLatestBtn] = useState(false);
  const containerRef = useRef(null);

  const sortedMessages = messages.sort(sortingDesToAsc);

  const onShowMenu = () => {
    setShowDropDown(true);
  };

  const onCloseMenu = () => {
    setShowDropDown(false);
  };

  useEffect(() => {
    if (containerRef && containerRef.current) {
      const element = containerRef.current;
      element.scroll({
        top: element.scrollHeight,
        behavior: 'smooth',
        left: 0,
      });
    }
  }, [containerRef, messages]);

  const onScrollElement = useCallback(
    debounce(() => {
      if (containerRef?.current) {
        const { scrollTop, scrollHeight, clientHeight } = containerRef.current;

        if (Number(scrollTop) + Number(clientHeight) >= scrollHeight - 5) {
          setShowLatestBtn(false);
        }

        if (Number(scrollTop) + Number(clientHeight) + 100 < scrollHeight) {
          setShowLatestBtn(true);
        }
      }
    }, 50),
    [containerRef?.current],
  );

  /**
   * @Name getConnectionDetailsByUserId
   * @params userId
   * @description This method is used to get profile picture by userId
   */
  const getConnectionDetailsByUserId = (userId: string) => {
    return chatListItemList?.find(item => item?.connectionId === userId);
  };
  return (
    <Box className={classes.container}>
      <Box className={classes.chatTop}>
        <Box className={clsx(classes.header, classes.header2)}>
          <Heading weight={fontWeight.BOLD} className={classes.title}>
            Chats
          </Heading>
          <IconButton icon="close" onClick={onClose} className={classes.backBtn} />
        </Box>
        <Box className={classes.chatListItemFlex} gap="12px" onClick={onShowMenu} sx={{ cursor: 'pointer' }}>
          <Avatar
            src={appointment?.participantImage}
            name={appointment?.participantName}
            size={40}
            variant={avatarType.CIRCLE}
          />
          <Box
            className={classes.onlinePresenceIndicator}
            style={{
              backgroundColor: isUserOnline(onlineUsers, appointment.participantId) ? '#51A365' : '#7d7d7d',
              color: isUserOnline(onlineUsers, appointment.participantId) ? '#51A365' : '#7d7d7d',
              // marginTop: -12,
            }}
          >
            .
          </Box>
          <Box>
            <Heading weight={fontWeight.SEMI_BOLD} className={classes.chatUsername}>
              {appointment?.participantName}
            </Heading>
            <Badge variant={badgeType.OUTLINED} className={classes.careTeamBadge}>
              Care team
            </Badge>
          </Box>
          <Icons glyph="chevron-down" className={classes.menuIcon} />
          {showDropDown && (
            <ClickAwayListener onClickAway={onCloseMenu}>
              <Box className={classes.menu}>
                <Box className={classes.menuItem}>
                  {chatListItemList?.map((chatListItem: IChatListItem, index: number) => (
                    <ChatListItem chatListItem={chatListItem} key={index} />
                  ))}
                </Box>
              </Box>
            </ClickAwayListener>
          )}
        </Box>
      </Box>
      <Box
        className={isWaiting ? classes.sessionBody : classes.body2}
        ref={containerRef}
        onScroll={onScrollElement}
      >
        {sortedMessages?.map((msg, index) => (
          <Box className={classes.messageWrap} key={index}>
            <UserChatCard
              key={uuid4()}
              {...msg}
              createdDate={msg?.createdAt}
              thumbnail={
                profile.providerId !== msg?.user?.userId
                  ? getConnectionDetailsByUserId(msg?.user?.userId)?.avatar
                  : profile?.profileImage
              }
              colorCode={
                profile.providerId !== msg?.user?.userId
                  ? getConnectionDetailsByUserId(msg?.user?.userId)?.colorCode
                  : null
              }
              senderId={msg.user.userId}
            />
          </Box>
        ))}
        {showLatestBtn && (
          <Box className={clsx(classes.latest, !sessionStarted && classes.pb7)}>
            <IconButton
              icon="arrow-left"
              className={classes.latestBtn}
              variant={btnType.PRIMARY}
              onClick={() => {
                containerRef?.current?.scroll({
                  top: containerRef?.current?.scrollHeight,
                  behavior: 'smooth',
                  left: 0,
                });
              }}
            >
              Latest messages
            </IconButton>
          </Box>
        )}
      </Box>
      <Box className={sessionStarted ? classes.footer2 : classes.footer}>
        <MessageInput onSubmit={handleAddMessage} />
      </Box>
    </Box>
  );
};

export { ChatUser };

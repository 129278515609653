import { makeStyles } from '@mui/styles';
import { colors } from '@confidant-health/lib/colors';

interface PropsT {
  type?: string;
}

export const useStyles = makeStyles({
  lottieSize: {
    width: 80,
    height: 80,
  },
  notesBadge: {
    border: '1px solid #98A1AA',
    height: '28px',
    padding: '4px 12px 4px 12px',
    gap: '8px',
    borderRadius: '4px 0px 0px 0px',
    color: colors.dark,
    lineClamp: 1,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  iconWrapper: ({ type }: PropsT) => ({
    minHeight: 40,
    minWidth: 40,
    borderRadius: 4,
    border: '1px solid',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center  ',
    backgroundColor: colors[`${type === 'normal' ? 'purple' : type}50`],
    borderColor: colors[`${type === 'normal' ? 'purple' : type}200`],
    '&.RELEASE_OF_INFORMATION': {
      backgroundColor: colors.indigo50,
      borderColor: colors.indigo200,
      '& svg path': {
        fill: colors.indigo500,
      },
    },
    '&.LAB_REQUEST': {
      backgroundColor: colors.green50,
      borderColor: colors.green200,
      '& svg path': {
        fill: colors.green500,
      },
    },
    '&.CONTACT': {
      backgroundColor: colors.rose50,
      borderColor: colors.rose200,
      '& svg path': {
        fill: colors.rose500,
      },
    },
    '&.PHARMACY_ASSISTANCE': {
      backgroundColor: colors.cyan50,
      borderColor: colors.cyan100,
      '& svg path': {
        fill: colors.cyan500,
      },
    },
    '&.REFERRAL_OUT': {
      backgroundColor: colors.orange50,
      borderColor: colors.orange200,
      '& svg path': {
        fill: colors.orange500,
      },
    },
    '&.PRIOR_AUTHORIZATION': {
      backgroundColor: colors.primary50,
      borderColor: colors.primary100,
      '& svg path': {
        fill: colors.primary500,
      },
    },
    '&.GENERAL': {
      backgroundColor: colors.purple50,
      borderColor: colors.purple200,
      '& svg path': {
        fill: colors.purple500,
      },
    },
  }),
  noteName: {
    lineClamp: 1,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  profileInfo: {
    '& .sb-avatar__image': {
      maxWidth: 'unset !important',
    },
  },
});

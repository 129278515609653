import React, { FC, useState } from 'react';
import { Box, Grid } from '@mui/material';
import { Badge, badgeSize, badgeStyle, badgeType } from '@confidant-health/lib/ui/atoms/badge';
import { colors } from '@confidant-health/lib/colors';
import { iconBtnPosition, IconButton } from '@confidant-health/lib/ui/molecules/icon-button';

import BoxFrame from 'components/v2/BoxFrame';
import Header from 'components/v2/Header';
import Typography from 'components/v2/Typography';
import { HeadingFont, HeadingLevel, HeadingWeight } from 'components/v2/Heading/index.types';
import { typography } from 'components/v2/Typography/index.constant';
import { Icons, IGlyphs } from '@confidant-health/lib/icons';
import { INote } from '@confidant-health/lib/ui/templates/note-card';
import { NoteStyle } from '@confidant-health/lib/constants/CommonConstants';
import { ProfileInfo } from '@confidant-health/lib/ui/templates/profile-info';
import cx from 'clsx';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

import { useStyles } from './caseNotes.styles';
import { NoteIcons } from '../../../../constants/CommonConstants';
import AddNoteDrawer from '../../member-detail/components/Notes/AddNoteDrawer/AddNoteDrawer';
import NoteDrawer from '../../member-detail/components/Notes/NoteDrawer';

dayjs.extend(customParseFormat);

type Props = {
  notes: INote[];
  total: number;
  onViewAll?: () => void;
  memberId?: string;
  fetchNotes: () => void;
  caseNotesLoader: boolean;
};

const RecentCaseNotes: FC<Props> = ({ notes, memberId, total, onViewAll, fetchNotes, caseNotesLoader }) => {
  const [selectedNote, setSelectedNote] = useState<INote | null>(null);
  const [isOpenAddNote, setIsOpenAddNote] = useState(false);
  let type = '';
  const classes = useStyles({ type });

  const transformText = (text: string) => {
    const str = text.toLowerCase().split('_').join(' ');
    return str?.charAt(0)?.toUpperCase() + str?.slice(1)?.toLowerCase();
  };

  const onClickAddNewNote = () => {
    setIsOpenAddNote(true);
  };

  const handleDeselectNote = () => {
    setSelectedNote(null);
  };

  const onUpdateNote = (note: INote) => {
    setSelectedNote(note);
    onClickAddNewNote();
  };

  const onCloseAddNote = () => {
    setIsOpenAddNote(false);
    setSelectedNote(null);
  };

  return (
    <BoxFrame>
      <AddNoteDrawer
        isOpen={isOpenAddNote}
        onClose={onCloseAddNote}
        fetchNotes={fetchNotes}
        note={selectedNote}
        memberId={memberId}
      />
      <NoteDrawer
        selectedNote={isOpenAddNote ? undefined : selectedNote}
        onClose={handleDeselectNote}
        onUpdateNote={onUpdateNote}
      />
      <Grid container>
        <Grid item xs={12} md={9}>
          <Box py={2.5} px={3}>
            <Header
              label="Recent case notes"
              fontFamily={HeadingFont.PRIMARY}
              fontVariant={HeadingLevel.SUB}
              fontWeight={HeadingWeight.SEMI_BOLD}
              badge={
                total > 0 && (
                  <Badge variant={badgeType.OUTLINED} style={badgeStyle.INACTIVE} size={badgeSize.XSMALL}>
                    {total} total
                  </Badge>
                )
              }
            />
          </Box>
        </Grid>
        {onViewAll && (
          <Grid item xs={12} md={3} display="flex" justifyContent="flex-end" alignItems="center">
            <Box sx={{ '& button': { gap: 1 } }} pr={2}>
              <IconButton icon="arrow-right" iconPosition={iconBtnPosition.RIGHT} onClick={onViewAll}>
                <Typography {...typography.body.s.bold}>View all</Typography>
              </IconButton>
            </Box>
          </Grid>
        )}
        {caseNotesLoader ? (
          <Grid item container sx={{ borderTop: `1px solid ${colors.neutral100}` }}>
            <Grid item xs={12} md={12}>
              <Box display="flex" justifyContent="center" sx={{ paddingY: 5 }}>
                Loading...{' '}
                <Icons className="rotate linear infinite" glyph="in-progress" color={colors.primary} />
              </Box>
            </Grid>
          </Grid>
        ) : notes?.length === 0 ? (
          <Grid item container sx={{ borderTop: `1px solid ${colors.neutral100}` }}>
            <Grid item xs={12} md={12} style={{ padding: '40px' }}>
              <Box
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  flex: 1,
                  gap: '16px',
                }}
              >
                <Icons glyph="lottie-case-notes" className={classes.lottieSize} />
                <Typography {...typography.body.m.medium} color={colors.neutral900}>
                  No case notes have been recorded for this member yet.
                </Typography>
              </Box>
            </Grid>
          </Grid>
        ) : (
          notes?.map((item, index) => {
            type = NoteStyle[item?.flag] || NoteStyle.GENERAL;
            return (
              <Grid
                item
                container
                key={index}
                sx={{
                  borderTop: `1px solid ${colors.neutral100}`,
                  cursor: 'pointer',
                }}
                onClick={() => {
                  setSelectedNote(item);
                }}
              >
                <Grid item xs={12} md={7}>
                  <Box display="flex" gap={2} py={2.5} px={3} alignItems="Center">
                    <div className={cx(classes.iconWrapper, item?.flag)}>
                      <Icons color={colors[`${type}500`]} glyph={NoteIcons[item?.flag] as IGlyphs['glyph']} />
                    </div>
                    <Typography {...typography.body.m.medium} className={classes.noteName}>
                      {item.name}
                    </Typography>
                    <Box sx={{ '&>div': { borderRadius: 1 } }}>
                      <Badge
                        variant={badgeType.OUTLINED}
                        className={classes.notesBadge}
                        size={badgeSize.SMALL}
                      >
                        {transformText(item?.status)}
                      </Badge>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12} md={5} display="flex" justifyContent="flex-end" alignItems="center">
                  <Box
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    width="100%"
                    justifyContent="space-between"
                    gap={3}
                    pr={2}
                  >
                    <Box className={classes.profileInfo}>
                      <ProfileInfo
                        type="provider"
                        photo={item?.createdBy?.profileImage}
                        role={item?.createdBy?.designation}
                        fullName={item?.createdBy.name}
                      />
                    </Box>
                    <Typography {...typography.body.s.medium}>
                      {dayjs.utc(item?.createdAt)?.tz(dayjs.tz.guess())?.format('MM/DD/YYYY, hh:mm A')}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            );
          })
        )}
      </Grid>
    </BoxFrame>
  );
};

export default RecentCaseNotes;

import { makeStyles } from '@mui/styles';

// colors
import { colors } from '@confidant-health/lib/colors';

interface StyleProps {
  collapsed?: boolean;
  isCareTem?: boolean;
}

export const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    // justifyContent: 'space-between',
    paddingTop: 24,
    position: 'relative',
    marginLeft: -64,
    marginRight: -64,
    height: '100%',
    background: '#FFF',
  },
  messageBody: {
    height: 'calc(100vh - 228px)',
    overflow: 'auto',
    padding: '36px 32px 16px',
    display: 'flex',
    flexDirection: 'column',
    gap: 32,
    '& .no-msg': {
      textAlign: 'center',
      color: colors.neutral400,
    },
  },
  messageBodyHeight: {
    height: 'calc(95vh - 228px)',
  },
  careTeamMessageBodyHeight: {
    height: 'calc(95vh - 228px)',
  },
  careTeamAndRecommendationBodyHeight: {
    height: 'calc(86vh - 228px)',
  },
  recomendationResponse: {
    height: 'calc(81vh - 228px)',
  },
  messageBody3: {
    height: 'calc(85vh - 265px)',
    overflow: 'auto',
    padding: '36px 32px 16px',
    display: 'flex',
    flexDirection: 'column',
    gap: 32,
    '& .no-msg': {
      textAlign: 'center',
      color: colors.neutral400,
    },
  },
  replyMessageDisplay: {
    padding: 16,
    backgroundColor: colors.neutral50,
    height: 136,
    position: 'absolute',
    width: '100%',
    bottom: 70,
  },
  replyMessageDisplay2: {
    padding: 16,
    backgroundColor: colors.neutral50,
    height: 196,
    position: 'absolute',
    width: '100%',
    bottom: 70,
  },
  onlinePresenceIndicator: {
    position: 'absolute',
    width: 15,
    height: 15,
    backgroundColor: '#F9911D',
    border: '3px solid white',
    borderRadius: '50%',
    marginLeft: 28,
    marginTop: 27,
  },
  replyMessageDisplayTitle: {
    display: 'flex',
    flexDirection: 'row',
  },
  replyMessageDisplayTitleHeading: {
    width: 100,
  },
  replyMessageDisplayTitleBtn: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  messageBody2: {
    height: 'calc(93vh - 265px)',
    overflow: 'auto',
    padding: '36px 32px 16px',
    display: 'flex',
    flexDirection: 'column',
    gap: 32,
    '& .no-msg': {
      textAlign: 'center',
      color: colors.neutral400,
    },
  },
  messageBody12: {
    height: 'calc(105vh - 265px)',
    overflow: 'auto',
    padding: '36px 32px 16px',
    display: 'flex',
    flexDirection: 'column',
    gap: 32,
    '& .no-msg': {
      textAlign: 'center',
      color: colors.neutral400,
    },
  },
  bottomSpace: {
    bottom: '149px !important',
  },
  recomendationBottomSpace: {
    bottom: '176px !important',
  },
  messageBody8: {
    height: 'calc(100vh - 265px)',
    overflow: 'auto',
    padding: '36px 32px 16px',
    display: 'flex',
    flexDirection: 'column',
    gap: 32,
    '& .no-msg': {
      textAlign: 'center',
      color: colors.neutral400,
    },
  },
  messageBody4: {
    height: 'calc(91vh - 265px)',
    overflow: 'auto',
    padding: '36px 32px 16px',
    display: 'flex',
    flexDirection: 'column',
    gap: 32,
    '& .no-msg': {
      textAlign: 'center',
      color: colors.neutral400,
    },
  },
  messageBody9: {
    height: 'calc(83vh - 265px)',
    overflow: 'auto',
    padding: '36px 32px 16px',
    display: 'flex',
    flexDirection: 'column',
    gap: 32,
    '& .no-msg': {
      textAlign: 'center',
      color: colors.neutral400,
    },
  },
  messageBody10: {
    height: 'calc(88vh - 265px)',
    overflow: 'auto',
    padding: '36px 32px 16px',
    display: 'flex',
    flexDirection: 'column',
    gap: 32,
    '& .no-msg': {
      textAlign: 'center',
      color: colors.neutral400,
    },
  },
  messageBody11: {
    height: 'calc(74vh - 265px)',
    overflow: 'auto',
    padding: '36px 32px 16px',
    display: 'flex',
    flexDirection: 'column',
    gap: 32,
    '& .no-msg': {
      textAlign: 'center',
      color: colors.neutral400,
    },
  },
  messageBody5: {
    height: 'calc(91vh - 352px)',
    overflow: 'auto',
    padding: '36px 32px 16px',
    display: 'flex',
    flexDirection: 'column',
    gap: 32,
    '& .no-msg': {
      textAlign: 'center',
      color: colors.neutral400,
    },
  },
  messageBody6: {
    height: 'calc(91vh - 415px)',
    overflow: 'auto',
    padding: '36px 32px 16px',
    display: 'flex',
    flexDirection: 'column',
    gap: 32,
    '& .no-msg': {
      textAlign: 'center',
      color: colors.neutral400,
    },
  },
  messageBody7: {
    height: 'calc(91vh - 337px)',
    overflow: 'auto',
    padding: '36px 32px 16px',
    display: 'flex',
    flexDirection: 'column',
    gap: 32,
    '& .no-msg': {
      textAlign: 'center',
      color: colors.neutral400,
    },
  },
  messageBody13: {
    height: 'calc(91vh - 385px)',
    overflow: 'auto',
    padding: '36px 32px 16px',
    display: 'flex',
    flexDirection: 'column',
    gap: 32,
    '& .no-msg': {
      textAlign: 'center',
      color: colors.neutral400,
    },
  },
  messageText: {
    color: colors.neutral900,
    textAlign: 'left',
    lineHeight: '24px',
    wordWrap: 'break-word',
  },
  messageText2: {
    color: colors.neutral900,
    textAlign: 'left',
    lineHeight: '24px',
    wordWrap: 'break-word',
    fontSize: 14,
    fontWeight: '500',
    fontFamily: 'Manrope',
  },
  reloadBtn: {
    color: colors.white,
  },
  chatMessageButtonConatiner: {
    position: 'absolute',
    top: 10,
    right: 0,
    display: 'flex',
    flexWrap: 'wrap',
    alignContent: 'center',
    justifyContent: 'flex-end',
    gap: 8,
  },
  replyButton: {
    height: 40,
    minWidth: '40px !important',
    width: 40,
    padding: 0,
    // position: 'absolute',
    background: colors.white,
  },
  replyCloseBtn: {
    height: 48,
    minWidth: '48px !important',
    width: 48,
    padding: 0,
    marginTop: -12,
    // position: 'absolute',
  },
  userChatCard: {
    gap: 16,
    display: 'flex',
    flexDirection: 'row',
    position: 'relative',
    width: '100%',
    cursor: 'pointer',
    '& .userChatContentWrapper': {
      // width: '84%',
      gap: 4,
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
    },
    '& .userChatContentWrapper2': {
      maxWidth: '93%',
      gap: 4,
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
    },
    '& .highlightMentionedUser': {
      color: colors.primary600,
    },
    '& .replyMsgContainer': {
      // width: '84%',
      height: 104,
      overflowY: 'auto',
    },
    '& .replyMsgContainer2': {
      // width: '84%',
      height: 60,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    '& .userChatMessageWrapper': {
      width: 560,
      gap: 4,
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
    },
    '& .userChatMessageWrapper2': {
      gap: 4,
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
    },
    '& .userChatMessageWrapper3': {
      width: '100%',
      wordBreak: 'break-all',
      gap: 4,
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
    },
    '& .nickName': {
      lineHeight: '20px',
      color: colors.neutral900,
    },
    '& .time': {
      lineHeight: '16px',
      color: colors.neutral500,
    },
    '& .message': {
      lineHeight: '20px',
      color: colors.neutral700,
    },
    '& .userChatNameWrapper': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      gap: 8,
    },
  },
  highlighted: {
    backgroundColor: colors.neutral100,
  },
  userChatCard2: {
    gap: 16,
    display: 'flex',
    flexDirection: 'row',
    position: 'relative',
    width: '100%',
    cursor: 'pointer',
    marginLeft: 55,
    backgroundColor: colors.neutral50,
    padding: 16,
    maxWidth: 558,
    '& .userChatContentWrapper': {
      // width: '84%',
      gap: 4,
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
    },
    '& .highlightMentionedUser': {
      color: colors.primary600,
    },
    '& .replyMsgContainer': {
      // width: '84%',
      height: 55,
      overflowY: 'auto',
    },
    '& .userChatMessageWrapper': {
      width: 470,
      gap: 4,
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
    },
    '& .nickName': {
      lineHeight: '20px',
      color: colors.neutral900,
    },
    '& .time': {
      lineHeight: '16px',
      color: colors.neutral500,
    },
    '& .message': {
      lineHeight: '20px',
      color: colors.neutral700,
    },
    '& .userChatNameWrapper': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      gap: 8,
    },
  },
  userChatCard3: {
    gap: 16,
    display: 'flex',
    flexDirection: 'row',
    position: 'relative',
    width: '100%',
    cursor: 'pointer',
    // marginLeft: 55,
    '& .userChatContentWrapper': {
      backgroundColor: colors.neutral50,
      padding: 16,
      maxWidth: 558,
      gap: 12,
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'row',
      marginLeft: 55,
    },
    '& .highlightMentionedUser': {
      color: colors.primary600,
    },
    '& .userChatMessageWrapper': {
      width: 560,
      gap: 4,
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
    },
    '& .nickName': {
      lineHeight: '20px',
      color: colors.neutral900,
    },
    '& .time': {
      lineHeight: '16px',
      color: colors.neutral500,
    },
    '& .message': {
      lineHeight: '20px',
      color: colors.neutral700,
    },
    '& .userChatNameWrapper': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      gap: 8,
    },
  },
  loadMoreContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  avt: {
    minWidth: 40,
  },
  footer: {
    height: 'auto',
    background: '#ffffff',
    // borderTop: `1px solid ${colors.neutral100}`,
    // position: 'absolute',
    bottom: -64,
    width: '100%',
    padding: 32,
    '& .MuiFormControl-root': {
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
    },
  },
  footer2: {
    height: 'auto',
    background: '#ffffff',
    // borderTop: `1px solid ${colors.neutral100}`,
    position: 'absolute',
    bottom: -64,
    width: '100%',
    padding: 32,
    '& .MuiFormControl-root': {
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
    },
  },
  header: {
    background: '#FBFCFE',
    // display: 'flex',
    alignItems: 'center',
    paddingTop: 15,
    justifyContent: 'space-between',
    position: 'absolute',
    top: -48,
    left: 0,
    height: 72,
    width: '100%',
    paddingLeft: 16,
    paddingRight: 32,
    borderBottom: `1px solid ${colors.neutral100}`,
    '& .headerRightSide': {
      display: 'flex',
      flexDirection: 'column',
      gap: 4,
      alignItems: 'flex-end',
      '& .type': {
        lineHeight: '20px',
        color: colors.neutral900,
      },
      '& .loginDate': {
        lineHeight: '20px',
        color: colors.neutral500,
      },
    },
    '& .headerLeftSide': {
      display: 'flex',
      flexDirection: 'row',
      gap: 12,
      cursor: 'pointer',
      alignItems: 'center',
    },
    '& .headerLeftSidePointer': {
      display: 'flex',
      flexDirection: 'row',
      gap: 12,
      cursor: 'pointer',
      alignItems: 'center',
    },
    '& .userNameWrapper': {
      display: 'flex',
      flexDirection: 'column',
      gap: 0,
      '& .nickName': {
        lineHeight: '20px',
        color: colors.neutral900,
      },
      '& .fullName': {
        lineHeight: '20px',
        color: colors.neutral600,
      },
    },
    '& .userNameWrapper2': {
      display: 'flex',
      flexDirection: 'column',
      gap: 0,
      width: '50%',
      '& .nickName': {
        lineHeight: '20px',
        color: colors.neutral900,
      },
      '& .fullName': {
        lineHeight: '20px',
        color: colors.neutral600,
      },
    },
  },
  chatSelectContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flex: 1,
  },
  chatSelect: {
    '& .MuiSelect-select': {
      marginLeft: 8,
      color: colors.neutral600,
    },
    '& .MuiSelect-icon > path': {
      fill: colors.neutral900,
    },
  },
  rightSideHeader: {
    height: 72,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  tabs: {
    width: '100%',
    height: 72,
    '& > div > div': {
      position: 'relative',
      '&:before': {
        content: "''",
        position: 'absolute',
        width: '100%',
        height: 1,
        backgroundColor: colors.neutral100,
        bottom: 0,
      },
    },
    '& button': {
      textTransform: 'capitalize',
      fontSize: 14,
      height: 72,
      fontWeight: 700,
      color: colors.neutral600,
      '&.Mui-selected': {
        color: colors.neutral900,
      },
    },
    '& .MuiTabs-indicator': {
      height: 1,
      backgroundColor: colors.neutral900,
    },
  },
  providerPhone: {
    width: '45%',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  tabBadge: {
    minWidth: 22,
    height: 18,
    fontSize: 12,
    fontWeight: 600,
    lineHeight: '16px',
    padding: 0,
    borderColor: colors.secondary300,
    color: colors.secondary500,
  },
  headerWrap: {
    width: '100%',
    borderBottom: `1px solid ${colors.neutral100}`,
  },
  searchChat: {
    height: 40,
  },
  rightSideBarRoot: {
    display: 'flex',
    width: 400,
    flexDirection: 'column',
    alignItems: 'center',
    flex: 1,
    overflow: 'hidden',
  },
  userChatList: {
    paddingBottom: 24,
    width: '100%',
    flex: 1,
    overflowY: 'auto',
    '& .noRecord': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
  userContactCard: {
    padding: '8px 16px 8px 16px',
    paddingRight: 42,
    gap: 16,
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    position: 'relative',
    justifyContent: 'space-between',
    width: '100%',
    cursor: 'pointer',
    '&.isActive': {
      backgroundColor: colors.neutral50,
    },
    '& .userChatContentWrapper': {
      gap: 4,
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
    },
    '& .nickName': {
      fontSize: 14,
      lineHeight: '20px',
      color: colors.neutral900,
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      flex: 'none',
      maxWidth: ({ isCareTem }: StyleProps) => (isCareTem ? 140 : 160),
    },
    '& .fullName': {
      fontSize: 12,
      lineHeight: '16px',
      color: colors.neutral500,
    },
    '& .lastMessage': {
      lineHeight: '20px',
      color: colors.neutral700,
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      maxWidth: 160,
    },
    '& .lastMessageTime': {
      lineHeight: '20px',
      color: colors.neutral700,
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
    '& .userChatNameWrapper': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      gap: 3,
      maxWidth: '10rem',
      minWidth: '12rem',
    },
    '& .badge': {
      height: 18,
      border: `1px solid ${colors.orange300}`,
      color: colors.orange600,
      background: colors.white,
      fontSize: 12,
      fontWeight: 600,
      lineHeight: '16px',
      // minWidth: '90px',
      textWrap: 'nowrap',
    },
  },
  unreadMessageBadge: {
    background: colors.secondary500,
    borderColor: colors.secondary500,
    position: 'absolute',
    color: colors.white,
    right: 6,
    // height: 18,
    fontSize: 12,
    width: '1.125rem',
    height: '1.125rem',
    borderRadius: '50%',
    padding: 0,
    // paddingLeft: 8,
    // paddingRight: 8,
    // borderRadius: 99,
  },
  fileThumbnail: {
    height: 200,
    width: 180,
  },
  currentUserChatCard: {
    gap: 16,
    display: 'flex',
    flexDirection: 'row',
    position: 'relative',
    width: '100%',
    justifyContent: 'flex-end',
    '& $avt, & .nickName': {
      lineHeight: '20px',
      color: colors.neutral900,
    },
    '& .userChatContentWrapper': {
      gap: 4,
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
    },
    '& .nickName': {
      lineHeight: '20px',
      color: colors.neutral900,
    },
    '& .time': {
      lineHeight: '16px',
      color: colors.neutral500,
    },
    '& .message': {
      lineHeight: '20px',
      color: colors.neutral700,
      textAlign: 'right',
    },
    '& .userChatNameWrapper': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-end',
      gap: 8,
      maxWidth: '10rem',
    },
  },
  emptyState: {
    gap: '0px',
  },
  latest: {
    position: 'absolute',
    bottom: 90,
    left: '50%',
    transform: 'translate(-50%, -50%)',
    // width: '100%',
    // display: 'flex',
    // justifyContent: 'center',
  },
  latest2: {
    position: 'absolute',
    bottom: 225,
    left: '50%',
    transform: 'translate(-50%, -50%)',
    // width: '100%',
    // display: 'flex',
    // justifyContent: 'center',
  },
  latestBtn: {
    height: 32,
    borderRadius: 30,
    color: colors.white,
    '& svg': {
      transform: 'rotate(-90deg)',
      width: 20,
    },
  },
  chatContainer: {
    display: 'grid !important',
    gridTemplateColumns: '3% 10% 45% 40%',
  },
  loeContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: 2,
    marginLeft: 12,
  },

  active: {
    background: '#2DA834',
    color: 'white',
    fontSize: '13px',
    height: 24,
    padding: '2px 10px',
    textAlign: 'center',
  },
  activeOutlined: {
    borderColor: colors.success400,
    fontSize: '13px',
    color: '#268C2B',
    padding: '2px 10px',
    textAlign: 'center',
  },
  headContainer: {
    display: 'flex',
    gap: 4,
    marginLeft: 'auto',
  },
  colLabel: {
    color: colors.neutral500,
  },
  subStatus: {
    marginTop: 1,
    height: 24,
  },
  divider: {
    borderLeft: '1px solid #D4DDE5',
    paddingRight: 20,
    height: 40,
    marginTop: -1,
  },
  divPadding: {
    marginRight: 20,
  },
  colLabel2: {
    paddingTop: 1,
    gap: '4px !important',
    lineHeight: 16,
    paddingRight: 7,
  },
  engaged: {
    background: colors.success500,
  },
  outreachNeeded: {
    background: colors.warning600,
  },
  notEngaged: {
    background: colors.destructive500,
  },
  todoDates: {
    fontWeight: '700',
  },
  link: {
    fontFamily: 'Manrope',
    fontWeight: 600,
    fontSize: 14,
    lineHeight: '19px',
    color: colors.primary600,
    transition: '0.4s',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
      opacity: 0.8,
    },
  },
  drawer: {
    '& .MuiPaper-root': {
      maxWidth: '560px !important',
    },
    '& .drawerBody': {
      minHeight: 'calc(100vh - 80px)',
      flexDirection: 'column',
      display: 'flex',
      padding: 0,
    },
  },
  chatBtn: {
    minWidth: 40,
    width: 40,
    height: 40,
    border: `1px solid ${colors.primary300}`,
  },
  chatBtnDisabled: {
    minWidth: 40,
    width: 40,
    height: 40,
  },
  availableBadge: {
    height: 28,
    background: colors.neutral50,
    borderRadius: 6,
    paddingTop: 4,
    paddingBottom: 4,
    paddingLeft: 12,
    paddingRight: 12,
  },
  availableBadgeFitWidth: {
    height: 28,
    background: colors.neutral50,
    borderRadius: 6,
    paddingTop: 4,
    paddingBottom: 4,
    paddingLeft: 12,
    paddingRight: 12,
    minWidth: 'fit-content',
    width: 'fit-content',
  },
  toggleWrapper: {
    display: 'flex',
    felxDirection: 'row',
    color: colors.primary600,
  },
  chevronIcon: {
    width: 24,
    minWidth: 24,
    height: 24,
    objectFit: 'contain',
    transition: '0.4s',
    marginLeft: '12px',
    marginRight: '12px',
    transform: ({ collapsed }: StyleProps) => `rotate(${collapsed ? '180deg' : 0})`,
  },
  toggleText: {
    curser: 'pointer',
  },
  badgeWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  badgeWrapperAlignStart: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    minWidth: 90,
    paddingTop: 2,
    justifyContent: 'flex-end',
  },
  profileWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    gap: 4,
  },
  profileWrapperCenterAligned: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 4,
  },
  careNavigatorBadge: {
    border: `1px solid ${colors.neutral200}`,
    borderRadius: 100,
    color: colors.neutral700,
    padding: '0px 8px',
  },
  iconBtn: {
    '& button': {
      minWidth: 40,
      height: 40,
      padding: 0,
      border: `1px solid ${colors.primary300}`,
    },
  },
  careTeambar: {
    padding: 16,
    borderBottom: `1px solid ${colors.neutral100}`,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  joinCallbar: {
    padding: 6,
    paddingLeft: 16,
    background: colors.primary500,
    display: 'flex',
    gap: 32,
    borderRadius: 6,
    alignItems: 'center',
  },
  providerBadge: {
    height: 18,
    minHeight: 'fit-content',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 100,
    background: colors.indigo50,
    fontSize: 12,
    fontWeight: 600,
    lineHeight: '16px',
    color: colors.indigo600,
    paddingLeft: 8,
    paddingRight: 8,
    marginLeft: 8,
  },
  badge: {
    height: 22,
    borderRadius: 100,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    maxWidth: 160,
    minWidth: 'fit-content',
  },
  provider: {
    color: colors.indigo600,
    background: colors.indigo50,
  },
  supervising: {
    color: colors.green600,
    background: colors.green50,
  },
  care: {
    color: colors.orange600,
    background: colors.orange50,
  },
  coach: {
    color: colors.warning600,
    background: colors.warning50,
  },
  member: {
    color: colors.rose600,
    background: colors.rose50,
  },
  designation: {
    color: colors.destructive600,
    background: colors.destructive50,
  },
  loadMoreBtn: {
    display: 'flex',
  },
  loadMoreBtnLoading: {
    display: 'flex',
    animation: `$ckw 3000ms`,
    animationDuration: '5.5s',
    animationIterationCount: 'infinite',
    transformOrigin: '50% 50%',
  },
  '@keyframes ckw': {
    '0%': {
      transform: 'rotate(0deg)',
    },
    '100%': {
      transform: 'rotate(360deg)',
    },
  },
  loadMoreBtnWrapper: {
    display: 'flex',
    justifyContent: 'center',
    color: colors.primary,
    fontWeight: 500,
    fontSize: 18,
  },
  loadMoreBtnTextWrapper: {
    display: 'flex',
    justifyContent: 'center',
    cursor: 'pointer',
    color: colors.textPrimary,
    fontWeight: 500,
    fontSize: 22,
    marginLeft: '-15px',
  },
  loadMoreBtnTextWrapperDisabled: {
    display: 'flex',
    justifyContent: 'center',
    color: colors.textPrimary,
    fontWeight: 500,
    fontSize: 22,
    marginLeft: '-15px',
  },
  label: {
    fontSize: 14,
    lineHeight: '20px',
    margin: 0,
  },
  section: {
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
    position: 'relative',
  },
  content: {
    display: 'flex',
    paddingLeft: 26,
    paddingRight: 26,
    paddingTop: 26,
    flexDirection: 'column',
  },
  defaultCareNavigatorInput: {
    height: '48px',
  },
});

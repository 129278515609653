// colors
import { colors } from '@confidant-health/lib/colors';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  container: {
    flex: 1,
    position: 'relative',
    zIndex: 1,
  },
  header: {
    backgroundColor: colors.neutral25,
    borderBottom: `1px solid ${colors.neutral100}`,
    width: '100%',
    height: 80,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '20px 16px',
  },
  header2: {
    borderBottom: 'none',
    borderTop: 'none',
    height: 'auto',
    padding: 0,
  },
  onlinePresenceIndicator: {
    position: 'absolute',
    width: 15,
    height: 15,
    backgroundColor: '#F9911D',
    border: '3px solid white',
    borderRadius: '50%',
    marginLeft: 28,
    marginTop: 27,
  },
  chatTop: {
    height: 118,
    padding: 16,
    backgroundColor: colors.neutral25,
    borderTop: `1px solid ${colors.neutral100}`,
    borderBottom: `1px solid ${colors.neutral100}`,
  },
  title: {
    fontSize: 18,
    lineHeight: '24px',
    margin: 0,
  },
  backBtn: {
    width: 40,
    minWidth: 40,
    height: 40,
    '& svg': {
      height: 20,
    },
  },
  body: {
    height: 'calc(100vh - 279px)',
    padding: 32,
    overflowY: 'auto',
    backgroundColor: colors.white,
  },
  body2: {
    overflowY: 'auto',
    height: 'calc(100vh - 279px)',
    padding: '16px 32px 5px',
    backgroundColor: colors.white,
    overflowX: 'hidden',
  },
  sessionBody: {
    height: 'calc(100vh - 222px)',
    padding: '16px 32px 5px',
    overflowY: 'auto',
    backgroundColor: colors.white,
    position: 'relative',
  },
  footer: {
    backgroundColor: colors.neutral25,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '16px 6px 16px 24px',
    borderTop: `1px solid ${colors.neutral100}`,
    width: '100%',
  },
  footer2: {
    backgroundColor: colors.neutral25,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '16px 6px 16px 24px',
    borderTop: `1px solid ${colors.neutral100}`,
    position: 'absolute',
    width: '100%',
    marginTop: -15,
  },
  doneBtn: {
    color: colors.white,
  },
  chatListItem: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    cursor: 'pointer',
    '&:not(:last-child)': {
      marginBottom: 30,
    },
  },
  chatListItemFlex: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    '& .sb-avatar__image': {
      maxWidth: 'unset !important',
    },
  },
  chatUsername: {
    fontSize: 14,
    lineHeight: '18px',
    margin: 0,
  },
  careTeamBadge: {
    height: 22,
    fontSize: 12,
    lineHeight: '16px',
    marginTop: 6,
    border: '1px solid #F7B27A',
    color: colors.orange600,
  },
  chatBadge: {
    borderRadius: 20,
    height: 22,
    fontSize: 12,
    lineHeight: '16px',
    marginTop: 6,
    border: 'none',
    '&.PATIENT': {
      backgroundColor: '#FFF1F3',
      color: '#E31B53',
    },
    '&.PRACTITIONER': {
      backgroundColor: colors.indigo50,
      color: colors.indigo600,
    },
    '&.Coach': {
      backgroundColor: colors.warning50,
      color: colors.warning600,
    },
    '&.MATCH_MAKER': {
      backgroundColor: colors.cyan50,
      color: colors.cyan600,
    },
  },
  iconBtn: {
    '& svg': {
      marginRight: 8,
    },
    fontSize: 14,
  },
  deleteChatBtn: {
    color: colors.destructive600,
    '& svg path': {
      fill: colors.destructive600,
    },
  },
  menuIcon: {},
  messageWrap: {
    marginBottom: 16,
  },
  menu: {
    position: 'absolute',
    backgroundColor: colors.white,
    boxShadow:
      '0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)',
    borderRadius: 6,
    top: 50,
    width: 285,
    zIndex: 2,
  },
  menuItem: {
    maxHeight: 234,
    overflowY: 'auto',
    padding: 24,
    '&:last-child': {
      borderTop: `1px solid ${colors.neutral100}`,
    },
  },
  latest: {
    position: 'absolute',
    bottom: '1.5rem',
    width: '90%',
    display: 'flex',
    justifyContent: 'center',
  },
  pb7: { bottom: '7.5rem' },
  latestBtn: {
    height: 32,
    borderRadius: 30,
    color: colors.white,
    '& svg': {
      transform: 'rotate(-90deg)',
      width: 20,
    },
  },
});
